<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="仓库编号" prop="pharmacyNo">
						<Input v-model="formData.pharmacyNo" placeholder="请输入仓库编号" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="仓库名称" prop="pharmacyName">
						<Input
							v-model="formData.pharmacyName"
							placeholder="请输入仓库名称"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="温度警戒区间(℃)" prop="normal">
						<Row>
							<Col span="11">
								<Input v-model="formData.tempMin" placeholder="请输入最小值" />
							</Col>
							<Col span="2" style="text-align: center">-</Col>
							<Col span="11">
								<Input v-model="formData.tempMax" placeholder="请输入最大值" />
							</Col>
						</Row>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="湿度警戒区间(%)" prop="normal">
						<Row>
							<Col span="11">
								<Input v-model="formData.humMin" placeholder="请输入最小值" />
							</Col>
							<Col span="2" style="text-align: center">-</Col>
							<Col span="11">
								<Input v-model="formData.humMax" placeholder="请输入最大值" />
							</Col>
						</Row>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="地址" prop="pharmacyAddr">
						<Input
							v-model="formData.pharmacyAddr"
							placeholder="请输入仓库地址"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注" prop="remark">
						<Input
							type="textarea"
							v-model="formData.remark"
							placeholder="请输入备注"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/pharmacy/index"

const { apiAdd, apiUpdate, apiGet } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "updateId"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "新增药房",
			rules: {
				pharmacyName: {
					required: true,
					message: "仓库名称不能为空",
					trigger: "blur"
				},
				pharmacyNo: {
					required: true,
					message: "仓库编号不能为空",
					trigger: "blur"
				}
			},
			ctms_config: {
				onchange: html => {
					this.formData.content = html
				},
				onblur: html => {
					this.formData.content = html
				}
			}
		}
	},
	watch: {
		visible() {
			this.$refs.form.resetFields()
			this.formData = {}
			this.title = "新增药房"
			if (this.updateId) {
				this.title = "修改药房"
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGet({ id: this.updateId })

					if (res) {
						this.formData = {
							...this.formData,
							...res.data
						}
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								id: this.updateId
							})
						} else {
							res = await apiAdd({
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
