<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="仓库编号">
				<Input placeholder="请输入仓库编号" v-model="searchParams.pharmacyNO" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="仓库名称">
				<Input
					placeholder="请输入仓库名称"
					v-model="searchParams.pharmacyName"
				/>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button
				v-if="systemActionPermissions.indexOf('btn_pharmacy_add') > -1"
				@click="handleAddShow"
				>新增药房</Button
			>
		</CtmsAction>

		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>

		<!--新增记录侧边栏-->
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:updateId="updateId"
		></Add>
	</div>
</template>

<script>
import api from "@/api/pharmacy/index"

import { mapState } from "vuex"
import Add from "./Add.vue"

const { apiGetPage, apiDelete } = api

export default {
	name: "pharmacyList",
	components: {
		Add
	},
	data() {
		return {
			listData: [],
			columns: [
				{ title: "仓库编号", key: "pharmacyNo", minWidth: 120 },
				{ title: "仓库名称", key: "pharmacyName", minWidth: 150 },
				{
					title: "温度警戒区间",
					key: "temp",
					minWidth: 120,
					render: (h, params) => {
						let txt = ""
						if (!params.row.tempMin && !params.row.tempMax) {
							txt = ""
						} else {
							txt = `${params.row.tempMin ? params.row.tempMin : "0"}℃~${params.row.tempMax ? params.row.tempMax : "0"}℃`
						}
						return h("span", txt)
					}
				},
				{
					title: "湿度警戒区间",
					key: "protNo",
					minWidth: 120,
					render: (h, params) => {
						let txt = ""
						if (!params.row.humMin && !params.row.humMax) {
							txt = ""
						} else {
							txt = `${params.row.humMin ? params.row.humMin : "0"}%~${params.row.humMax ? params.row.humMax : "0"}%`
						}
						return h("span", txt)
					}
				},
				{
					title: "最新温度",
					key: "latestTemp",
					minWidth: 100,
					render: (h, params) =>
						h(
							"span",
							params.row.latestTemp !== null ? `${params.row.latestTemp}℃` : ""
						)
				},
				{
					title: "最新湿度",
					key: "latestHum",
					minWidth: 100,
					render: (h, params) =>
						h(
							"span",
							params.row.latestHum !== null ? `${params.row.latestHum}%` : ""
						)
				},
				{ title: "更新时间", key: "lasestRecordDate", minWidth: 180 },
				{ title: "状态", key: "status", minWidth: 100 },
				{
					title: "操作",
					key: "action",
					width: 230,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "温湿记录",
								on: {
									click: () => {
										this.$router.push({
											path: "/special/pharmacyList/humiture",
											query: {
												pharmacyId: params.row.id
											}
										})
									}
								}
							},
							{
								label: "详情",
								on: {
									click: () => {
										this.$router.push({
											path: "/special/pharmacyList/detail",
											query: {
												pharmacyId: params.row.id
											}
										})
									}
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_pharmacy_add") === -1
						) {
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				pharmacyName: "",
				pharmacyNO: ""
			},
			oldSearchParams: {}
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},

		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},

		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},

		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				pharmacyName: "",
				pharmacyNO: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		}
	}
}
</script>
