import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/pharmacy"
export default {
	...commonApi(moduleName),
	// 获取详情分页
	apigetDetailPage: data =>
		request(`/pharmacy/${data.id}/goods`, {
			method: "get",
			params: data
		})
}
